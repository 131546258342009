import { Button, Popover, Typography, Checkbox, FormControlLabel } from '@mui/material';
import { FormEvent, ReactElement, useCallback, ChangeEvent } from 'react';
import type { TableInstance } from 'react-table';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  columnsPopOver: {
    padding: 24,
  },
  filtersResetButton: {
    top: 18,
    right: 21,
  },
  popoverTitle: {
    fontWeight: 500,
    padding: '0 24px 24px 0',
    textTransform: 'uppercase',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 218px)',
    '@media (max-width: 600px)': {
      gridTemplateColumns: 'repeat(1, 180px)',
    },
    gridColumnGap: 24,
    gridRowGap: 24,
  },
  cell: {
    width: '100%',
    display: 'inline-flex',
    flexDirection: 'column',
  },
  hidden: {
    display: 'none',
  },
});

export interface ToggleOption {
    toggleName: string;
    onToggle: () => void;
    defaultValue: boolean;
}

interface FilterPageProps<T extends Record<string, unknown>> {
  instance: any
  anchorEl?: Element
  onClose: () => void
  show: boolean,
  toggleOptions?: Record<string, ToggleOption>
}

export function FilterPage<T extends Record<string, unknown>>({
  instance,
  anchorEl,
  onClose,
  show,
  toggleOptions,
}: FilterPageProps<T>): ReactElement {
  const { classes } = useStyles();
  const { allColumns, setAllFilters } = instance;

  const onSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      onClose();
    },
    [onClose]
  );

  const resetFilters = useCallback(() => {
    setAllFilters([]);

    if (toggleOptions) {
      Object.entries(toggleOptions).forEach(([key, { defaultValue, onToggle }]) => {
        if (defaultValue) {
          onToggle();
        }
      });
    }
  }, [setAllFilters, toggleOptions]);


  const renderColumns = () => {
    let filteredColumns = allColumns;

    if (filteredColumns.length > 9) {
      filteredColumns = filteredColumns.slice(-9);
    }

    return filteredColumns
      .filter((it: any) => it.canFilter)
      .map((column: any) => (
        <div key={column.id} className={classes.cell}>
          {column.render('Filter')}
        </div>
      ));
  };

  const renderToggles = () => {
    if (!toggleOptions) return null;


    return Object.entries(toggleOptions).map(([key, { toggleName, onToggle, defaultValue }]) => {
        if (!toggleName || !onToggle) return null;

        const handleSwitch = (event: ChangeEvent<HTMLInputElement>) => {
            try {
                Object.entries(toggleOptions).forEach(([otherKey, { onToggle, defaultValue }]) => {
                  if (defaultValue && otherKey !== key) {
                    onToggle();
                  }
                });
                onToggle();
              } catch (error: any) {
                console.error(`Error executing 'onToggle' for ${key}: ${error.message}`);
              }
        };

        return (
            <div key={key} className={classes.cell}>
            <FormControlLabel
                control={
                <Checkbox
                    color='primary'
                    onChange={handleSwitch}
                    checked={defaultValue}
                />
                }
                label={toggleName}
                labelPlacement='start'
                style={{
                alignSelf: 'start',
                marginLeft: '0',
                height: '100%',
                }}
            />
            </div>
        );
    });
  };

  return (
    <div>
      <Popover
        anchorEl={anchorEl}
        id='popover-filters'
        onClose={onClose}
        open={show}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={classes.columnsPopOver}>
          <Typography className={classes.popoverTitle}>Filtros</Typography>
          <form onSubmit={onSubmit}>
            <Button
              className={classes.filtersResetButton}
              color='primary'
              onClick={resetFilters}
              style={{ position: 'absolute' }}
            >
              Restablecer
            </Button>
            <div className={classes.grid}>
              {renderColumns()}
              {renderToggles()}
            </div>
            <Button className={classes.hidden} type='submit'>
              &nbsp;
            </Button>
          </form>
        </div>
      </Popover>
    </div>
  );
}
