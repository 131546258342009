import { useEffect, useState } from "react";
import { CssBaseline } from '@mui/material';
import { useCallback } from 'react';
import { FilterValue, IdType } from 'react-table';
import { IQuestion, IReport, ISimulation } from "../../../../interfaces";
import { Page } from "../../../tables/table/page";
import { DefaultTable } from "../../../tables/table/table";
import EditQuestionComponent from "../../questions/editQuestion";
import ModalInfoReport from "../ModalReportInfo";
import ReportService from "../../../../services/httpServices/report.service";
import QuestionService from "../../../../services/httpServices/question.service";
import ConfirmationModal from "../../../modals/ConfirmationModal";
import ToastService from "../../../../services/toastService";
import Skeleton from "react-loading-skeleton";


function filterGreaterThan(rows: any, id: Array<IdType<any>>, filterValue: FilterValue) {
  return rows.filter((row: any) => {
    const rowValue = row.values[id[0]];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val: any) => typeof val !== 'number';


const columns = [
  {
    Header: 'Reportes',
    columns: [
      {
        Header: 'Descripción',
        accessor: 'description',
        aggregate: 'count',
        filter: 'fuzzyText',
        Aggregated: ({ cell: { value } }: any) => `${value} Description`,
        disableGroupBy: true
      },
      {
        Header: 'Nombre de usuario',
        accessor: 'user.email',
        aggregate: 'uniqueCount',
        filter: 'fuzzyText',
        Aggregated: ({ cell: { value } }: any) => `${value} User`,
        disableGroupBy: true
      },
    ],
  },

];

export default function ListReportQuestions(): JSX.Element {

  const { showErrorToast, showSuccessToast } = ToastService();

  const { getReports, deleteReport } = ReportService();
  const { deleteQuestion } = QuestionService();
  const [data, setData] = useState<any[]>([]);
  const [openModalReportInfo, setOpenModalReportInfo] = useState(false);
  const [reportClicked, setReportCLicked] = useState<IReport>();
  const [isViewEditQuestion, setIsViewEditQuestion] = useState(false);
  const [questionToEdit, setQuestionToEdit] = useState<IQuestion>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteInstance, setDeleteInstance] = useState<any>(null);
  const [deleteType, setDeleteType] = useState<"report" | "question" | null>(null);
  const [buttonState, setButtonState] = useState({
    acceptText: 'Aceptar',
    disableButtons: false
  });

  useEffect(() => {
    async function getQuestions() {
      const fetchReports: any[] = await getReports();
      setData(fetchReports);
      setIsLoading(false);
    }
    getQuestions();
  }, []);


  const handleConfirmDelete = async () => {
    if (deleteType === "report" && deleteInstance) {
      const reportIds = deleteInstance.selectedFlatRows.map((res: any) => res.original._id);
      try {
        setButtonState({ acceptText: 'Eliminando...', disableButtons: true });
        await deleteReport(reportIds);
        setButtonState({ acceptText: 'Aceptar', disableButtons: false });
        setData((prevData) => prevData.filter((report) => !reportIds.includes(report._id)));
        deleteInstance.toggleAllRowsSelected(false);
        showSuccessToast(deleteInstance?.selectedFlatRows?.length > 1 ? "Se han eliminado los reportes" : "Se ha eliminado el reporte")
      } catch (error) {
        console.error("Error al eliminar el reporte:", error);
        showErrorToast("No se pudo eliminar el reporte.");
        setButtonState({ acceptText: 'Aceptar', disableButtons: false });
      }
    } else if (deleteType === "question" && deleteInstance) {
      try {
        setButtonState({ acceptText: 'Eliminando...', disableButtons: true });
        await deleteQuestion([deleteInstance]);
        setButtonState({ acceptText: 'Aceptar', disableButtons: false });
        setData((prevData) => prevData.filter((report) => report.question?._id !== deleteInstance));
        showSuccessToast("Se ha eliminado la pregunta y los reportes asociados")
      } catch (error) {
        console.error("Error al eliminar la pregunta:", error);
        showErrorToast("No se pudo eliminar la pregunta.");
        setButtonState({ acceptText: 'Aceptar', disableButtons: false });
      }
    }
    setIsDeleteModalOpen(false);
    setOpenModalReportInfo(false);
    setDeleteType(null);
  };

  const onDeleteReport = useCallback(
    (instance: any) => () => {
      setDeleteInstance(instance);
      setDeleteType("report");
      setIsDeleteModalOpen(true);
    },
    []
  );

  const onDeleteQuestion = (questionId: string) => {
      setDeleteInstance(questionId);
      setDeleteType("question");
      setIsDeleteModalOpen(true);
  }

  const handleQuestionUpdated = (updatedQuestion: any) => {
    setData((prevData) =>
      prevData.map((q) =>
        q.question && q.question._id === updatedQuestion._id ? { ...q, question: updatedQuestion } : q
      )
    );
  };

  const handleConfirmDeleteText = deleteType === "question" ? "¿Quieres borrar esta pregunta?" :
    deleteInstance?.selectedFlatRows?.length > 1 ? "¿Quieres eliminar estos reportes?" : "¿Quieres eliminar este reporte?";

  return (
    <>
    {isLoading ?
        <Skeleton className="min-w-[70vw]" height={'80vh'} /> :
        <>

            <ConfirmationModal
            text={handleConfirmDeleteText}
            onAccept={() => handleConfirmDelete()}
            onClose={() => setIsDeleteModalOpen(false)}
            visible={isDeleteModalOpen}
            acceptText={buttonState.acceptText}
            disabeldBottons={buttonState.disableButtons}
            />
            {
            isViewEditQuestion && questionToEdit ?
                <EditQuestionComponent question={questionToEdit} setIsViewEditQuestion={setIsViewEditQuestion} onQuestionUpdated={handleQuestionUpdated}/>
                :
                <div className="w-[70vw]">
                <DefaultTable<any>
                    name={'reportsTable'}
                    columns={columns}
                    data={data}
                    onDelete={onDeleteReport}
                    onClick={(e: any) => {
                        if (!e.original.question) {
                            showErrorToast("Este reporte ya ha sido eliminado.");
                            setData((prevData) => prevData.filter((report) => report._id !== e.original._id));
                            return;
                          }

                          setReportCLicked(e.original);
                          setOpenModalReportInfo(true);
                    }}
                />
                <div>
                    {
                    openModalReportInfo && reportClicked ?
                        <ModalInfoReport
                        setOpenModalReportInfo={setOpenModalReportInfo}
                        openModalReportInfo={openModalReportInfo}
                        diameter={100}
                        info={reportClicked}
                        setIsViewEditQuestion={setIsViewEditQuestion}
                        setQuestionToEdit={setQuestionToEdit}
                        onQuestionUpdated={handleQuestionUpdated}
                        onDeleteQuestion={onDeleteQuestion}
                        /> : <></>
                    }
                </div>
                </div>
            }
        </>
    }
    </>
  );
};
