export const PRIMARY_COLOR = '#6BCAE2';
export const FREE_TEST_TRIAL_URL = 'https://www.autenticatest.com/prueba-gratis-simulacro-test/';

/* HTTP */

export const HTTP_CODES = {
    CONTINUE: 100,
    SWITCHING_PROTOCOLS: 101,

    // [Successful 2xx]
    OK: 200,
    CREATED: 201,
    ACCEPTED: 202,
    NONAUTHORITATIVE_INFORMATION: 203,
    NO_CONTENT: 204,
    RESET_CONTENT: 205,
    PARTIAL_CONTENT: 206,

    // [Redirection 3xx]
    MULTIPLE_CHOICES: 300,
    MOVED_PERMANENTLY: 301,
    FOUND: 302,
    SEE_OTHER: 303,
    NOT_MODIFIED: 304,
    USE_PROXY: 305,
    UNUSED: 306,
    TEMPORARY_REDIRECT: 307,

    // [Client Error 4xx]
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    PAYMENT_REQUIRED: 402,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    METHOD_NOT_ALLOWED: 405,
    NOT_ACCEPTABLE: 406,
    PROXY_AUTHENTICATION_REQUIRED: 407,
    REQUEST_TIMEOUT: 408,
    CONFLICT: 409,
    GONE: 410,
    LENGTH_REQUIRED: 411,
    PRECONDITION_FAILED: 412,
    REQUEST_ENTITY_TOO_LARGE: 413,
    REQUEST_URI_TOO_LONG: 414,
    UNSUPPORTED_MEDIA_TYPE: 415,
    REQUESTED_RANGE_NOT_SATISFIABLE: 416,
    EXPECTATION_FAILED: 417,
    UNPROCESSABLE_ENTITY: 422,

    // [Server Error 5xx]
    INTERNAL_SERVER_ERROR: 500,
    NOT_IMPLEMENTED: 501,
    BAD_GATEWAY: 502,
    SERVICE_UNAVAILABLE: 503,
    GATEWAY_TIMEOUT: 504,
    VERSION_NOT_SUPPORTED: 505,
}

/* PASSWORD VALIDATORS */

export const PASS_MUST_INCLUDE_NUMBERS = true;
export const PASS_MUST_INCLUDE_LOWER_AND_UPPER_CASE = true;
export const PASS_MUST_INCLUDE_SPECIAL_CHAR = false;
export const PASS_MIN_LENGTH = 8;

/* TABLES PAGINATION */

export const ITEMS_PER_PAGE = 20;

/* BUTTON TYPES */

export const PRIMARY_BUTTON = 'primary';
export const SECONDARY_BUTTON = 'secondary';
export const DESIST_BUTTON = 'desist';
export const SECONDARY_DESIST_BUTTON = 'secondary_desist';
export const SECONDARY_GRAY_BUTTON = 'secondary_gray';
export const TRANSPARENT_BUTTON = 'transparent';
export const ICON_BUTTON = 'icon';
export const ROUND_BUTTON = 'round';
export const ROUND_RED_BUTTON = 'round_red';
export const ROUND_BLACK_BUTTON = 'round_black';
export const ROUND_SECONDARY_BUTTON = 'round_secondary';
export const ROUND_WHITE_BUTTON = 'round_white';
export const ADMIN_BUTTON = 'admin';
export const FLOATING_BUTTON = 'floating';

/* DROPDOWN STYLES */

export const SECONDARY_DROPDOWN = 'secondary';

/* INPUT TYPES */

export const PASSWORD = 'password';
export const SEARCH = 'search';

/* INPUT STYLES */

export const SECONDARY_INPUT = 'secondary';


/* COMPONENTS TYPES */

export const COMPONENTS_TYPES = {
  SELECT_CUSTOM_OR_LAW: 'selectCustomOrLaw',
}