import { useEffect, useState } from "react";
import { IQuestion } from "../../../../interfaces";
import { Button, Modal, TextField, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { CellProps, IdType, Row, FilterValue } from 'react-table';
import EditQuestionComponent from "../editQuestion";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import ModalInfoQuestion from "./ModalQuestionInfo";
import { TableQuestions } from "../../../tables/table-questions/table";
import QuestionService from "../../../../services/httpServices/question.service";
import ToastService from "../../../../services/toastService";
import UtilsService from "../../../../services/utils.service";
import Skeleton from "react-loading-skeleton";
import ModalReportQuestions from "../../../ModalReportQuestion/index";
import ConfirmationModal from "../../../modals/ConfirmationModal";


function filterGreaterThan(rows: Array<Row<any>>, id: Array<IdType<any>>, filterValue: FilterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id[0]];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val: any) => typeof val !== 'number';

const getMinMax = (rows: Row<any>[], id: IdType<any>) => {
  let min = rows.length ? rows[0].values[id] : 0;
  let max = rows.length ? rows[0].values[id] : 0;
  rows.forEach((row) => {
    min = Math.min(row.values[id], min);
    max = Math.max(row.values[id], max);
  });
  return [min, max];
};

function SliderColumnFilter({ column: { render, filterValue, setFilter, preFilteredRows, id } }: any) {
  const [min, max] = React.useMemo(() => getMinMax(preFilteredRows, id), [id, preFilteredRows]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <TextField
        name={id}
        label={render('Header')}
        type='range'
        inputProps={{
          min,
          max,
        }}
        value={filterValue || min}
        onChange={(e) => {
          setFilter(parseInt(e.target.value, 10));
        }}
        variant='standard'
      />
      <Button variant='outlined' style={{ width: 60, height: 36 }} onClick={() => setFilter(undefined)}>
        Off
      </Button>
    </div>
  );
}

const baseColumns = [
    {
      Header: 'Enunciado',
      accessor: 'statement',
      aggregate: 'count',
      Aggregated: ({ cell: { value } }: CellProps<any>) => `${value} Statement`,
      disableGroupBy: true,
    },
    {
      Header: 'Preparador',
      accessor: 'preparer',
      aggregate: 'count',
      filter: 'fuzzyText',
      Aggregated: ({ cell: { value } }: CellProps<any>) => `${value} Unique Preparer`,
      disableGroupBy: true
    },
    {
      Header: 'Bloque de materia',
      accessor: 'subject_block',
      aggregate: 'count',
      Aggregated: ({ cell: { value } }: CellProps<any>) => `${value} Subject_block`,
      disableGroupBy: true
    },
    {
      Header: 'Nivel',
      accessor: 'level',
      width: 50,
      minWidth: 50,
      align: 'right',
      Filter: SliderColumnFilter,
      filter: 'equals',
      aggregate: 'level',
      disableGroupBy: true,
      defaultCanSort: false,
      disableSortBy: false,
      Aggregated: ({ cell: { value } }: CellProps<any>) => `${value} (level)`,
    },
    {
      Header: 'Normativa',
      accessor: 'standard',
      aggregate: 'count',
      Aggregated: ({ cell: { value } }: CellProps<any>) => `${value} Standard`,
      disableGroupBy: true
    },
    {
      Header: 'Option_1',
      accessor: 'option_1',
      aggregate: 'count',
      Aggregated: ({ cell: { value } }: CellProps<any>) => `${value} Option_1`,
      disableGroupBy: true
    },
    {
      Header: 'Option_2',
      accessor: 'option_2',
      aggregate: 'count',
      Aggregated: ({ cell: { value } }: CellProps<any>) => `${value} Option_2`,
      disableGroupBy: true
    },
    {
      Header: 'Option_3',
      accessor: 'option_3',
      aggregate: 'count',
      Aggregated: ({ cell: { value } }: CellProps<any>) => `${value} Option_3`,
      disableGroupBy: true
    }, {
      Header: 'Option_4',
      accessor: 'option_4',
      aggregate: 'count',
      Aggregated: ({ cell: { value } }: CellProps<any>) => `${value} Option_4`,
      disableGroupBy: true
    },
  ];

export default function ListQuestionComponent(): JSX.Element {

  const { showErrorToast, showSuccessToast } = ToastService();

  const [data, setData] = useState<any[]>([]);
  const [isViewEditQuestion, setIsViewEditQuestion] = useState(false);
  const [questionToEdit, setQuestionToEdit] = useState<IQuestion>();
  const [totalQuestions, setTotalQuestions] = useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [viewStatementModal, setStatementModal] = useState<boolean>(false);
  const [statement, setStatement] = useState<IQuestion>();
  const [watchInfoQuestion, setWatchInfoQuestion] = useState<boolean>(false);
  const [watchQuestion, setWatchQuestion] = useState<IQuestion>();
  const [search, setSearch] = useState<string>('');
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [showDuplicates, setShowDuplicates] = useState<boolean>(false);
  const [isOrderByReport, setIsOrderByReport] = useState<boolean>(false);

  const { getAllQuestions, deleteQuestion, downloadQuestionsExcel, downloadQuestionsPDF, getDuplicateQuestions, getReportedQuestions } = QuestionService();
  const { downloadExcelblob, downloadPDFblob, downloadZip } = UtilsService();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteInstance, setDeleteInstance] = useState<any>(null);
  const [columnsState, setColumnsState] = useState<any[]>([]);
  const [buttonState, setButtonState] = useState({
    acceptText: 'Aceptar',
    disableButtons: false
  });


  useEffect(() => {
    setColumnsState([
      {
        Header: 'Preguntas',
        columns: isOrderByReport
          ? [
              {
                Header: 'Nº Reportes',
                accessor: 'reports_count',
                width: 80,
                align: 'center',
                disableGroupBy: true,
              },
              ...baseColumns,
            ]
          : baseColumns,
      },
    ]);
  }, [isOrderByReport]);

  function getQuestions() {

    setIsLoading(true);

    const fetchQuestionsPromise = Promise.resolve(showDuplicates ? getDuplicateQuestions() : isOrderByReport ? getReportedQuestions() : getAllQuestions());
    fetchQuestionsPromise
      .then((fetchQuestions) => {
        setData(fetchQuestions);
        setTotalQuestions(fetchQuestions.length);
        setIsLoading(false);
      })
      .catch((error) => {
        showErrorToast('Error al obtener las preguntas');
        setIsLoading(false);
        setData([]);
        setTotalQuestions(0);
      });
  }

  useEffect(() => {
    getQuestions();
  }, [showDuplicates, isOrderByReport]);

  const handleConfirmDelete = async () => {
    if (deleteInstance) {
      const q = deleteInstance.selectedFlatRows.map((res: any) => res.original._id);
      try {
        setButtonState({ acceptText: 'Eliminando...', disableButtons: true });
        await deleteQuestion(q);
        setButtonState({ acceptText: 'Aceptar', disableButtons: false });
        setData((prevData) => prevData.filter((question) => !q.includes(question._id)));
        deleteInstance.toggleAllRowsSelected(false);
        showSuccessToast(deleteInstance?.selectedFlatRows?.length > 1 ? "Se han eliminado las preguntas" : "Se ha eliminado la pregunta")
      } catch (error) {
        console.error("Error al eliminar la pregunta:", error);
        showErrorToast("No se pudo eliminar la pregunta.");
        setButtonState({ acceptText: 'Aceptar', disableButtons: false });
      }
      setIsDeleteModalOpen(false);
    }
  };

  const onDeleteQuestion = useCallback(
    (instance : any) => () => {
      setDeleteInstance(instance);
      setIsDeleteModalOpen(true);
    },
    []
  );

  const handleClose = () => {
    setStatementModal(false);
  };

  const onDownloadExcel = useCallback(
    (instance: any) => async () => {
      const ids = instance.flatRows.map((v: any) => v.original._id);
      await downloadExcel(ids);
    },
    []
  );

  const onDownloadPDF = useCallback(
    (instance: any) => async () => {
      const ids = instance.flatRows.map((v: any) => v.original._id);
      await downloadPDF(ids);
    },
    []
  );

  const downloadExcel = async (ids: string[]) => {
    setIsDownloading(true);
    try {
      const query = { "$text": { "$search": `"${search}"` } };
      const excel = await downloadQuestionsExcel(ids);
      setIsDownloading(false);
      downloadZip(excel, `Backup-preguntas-${Date.now()}`);
    } catch (error) {
      setIsDownloading(false);
      // showErrorToast('El excel no se ha descargado con éxito');
    }
  };

  const downloadPDF = async (ids: string[]) => {
    setIsDownloading(true);
    try {
      const query = { "$text": { "$search": `"${search}"` } };
      const pdf = await downloadQuestionsPDF(ids);
      setIsDownloading(false);
      downloadPDFblob(pdf, `Backup-preguntas-${Date.now()}`);
    } catch (error) {
      setIsDownloading(false);
      showErrorToast('El pdf no se ha descargado con éxito');
    }
  };

  const handleQuestionUpdated = (updatedQuestion: any) => {
    setData((prevData) =>
      prevData.map((q) => (q._id === updatedQuestion._id ? updatedQuestion : q))
    );
  };

  const handleConfirmDeleteText = deleteInstance?.selectedFlatRows?.length > 1
  ? "¿Quieres borrar estas preguntas?"
  : "¿Quieres borrar esta pregunta?";

  const toggleOptions = {
    duplicates: {
      onToggle: () => setShowDuplicates((prev) => !prev),
      toggleName: 'Mostrar Duplicados',
      defaultValue: showDuplicates,
    },
    reports: {
      onToggle: () => setIsOrderByReport((prev) => !prev),
      toggleName: 'Con reportes asignados',
      defaultValue: isOrderByReport,
    },
  };


  return (
    <>
        <ConfirmationModal
        text={handleConfirmDeleteText}
        onAccept={() => handleConfirmDelete()}
        onClose={() => setIsDeleteModalOpen(false)}
        visible={isDeleteModalOpen}
        acceptText={buttonState.acceptText}
        disabeldBottons={buttonState.disableButtons}
        />
        <Modal
        open={viewStatementModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
            <Box className="bg-white">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Enunciado de la pregunta
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {statement?.statement}
                </Typography>
            </Box>
        </Modal>
        {isViewEditQuestion && questionToEdit ?
            <EditQuestionComponent question={questionToEdit} setIsViewEditQuestion={setIsViewEditQuestion} onQuestionUpdated={handleQuestionUpdated}/>
            :
            <>
              {isDownloading ?
                <Skeleton className="min-w-[70vw]" height={'80vh'} /> :
                <div className="w-[70vw]">
                    <TableQuestions<any>
                        name={'testTable'}
                        columns={columnsState}
                        data={data}
                        isLoading={isLoading}
                        toggleOptions={toggleOptions}
                        onDelete={onDeleteQuestion}
                        onClick={(e: any) => {
                        setWatchQuestion(e.original);
                        setWatchInfoQuestion(true);
                        }}
                        onDownloadExcel={onDownloadExcel}
                        onDownloadPDF={onDownloadPDF}
                    />
                  <div>
                    {watchInfoQuestion && watchQuestion &&
                      <ModalReportQuestions
                        type="question"
                        setOpenModalReportInfo={setWatchInfoQuestion}
                        openModalReportInfo={watchInfoQuestion}
                        diameter={100}
                        info={{ question: watchQuestion }}
                        setIsViewEditQuestion={setIsViewEditQuestion}
                        setQuestionToEdit={setQuestionToEdit}
                        onQuestionUpdated={handleQuestionUpdated}
                      />
                    }
                  </div>
                </div>
              }
            </>
        }
    </>
  );
};
