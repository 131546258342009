import { useState, useEffect } from "react";
import errorService from "../../../../services/error.service";
import useDragAndDrop from "./dragAndDrop";
import './index.scss';
import QuestionService from "../../../../services/httpServices/question.service";
import ToastService from "../../../../services/toastService";
import ConfirmationModal from "../../../modals/ConfirmationModal";
import UploadQuestionsButton from "../../../buttons/UploadQuestionsButton";
import { HTTP_CODES } from '../../../../config/constants';

export default function UploadQuestionComponent(): JSX.Element {

  const { showErrorToast, showSuccessToast } = ToastService();
  const {
    dragOver,
    setDragOver,
    onDragOver,
    onDragLeave,
    fileDropError,
    setFileDropError
  } = useDragAndDrop();
  const { uploadQuestions } = QuestionService();

  const [fileSelected, setFileSelected] = useState(false);
  const [file, setFile] = useState<File | undefined>();
  const [repeated, setRepeated] = useState(0);
  const [viewRepeatedModal, setRepeatedModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const [uploading, setUploading] = useState(false);

  const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragOver(false);

    const selectedFile = e?.dataTransfer?.files[0];
    if (!selectedFile) {
      setFileSelected(false);
      return;
    }

    const type = selectedFile.name.split('.').pop();
    if (type !== "xlsx") {
      setShowError(true);
      setFileDropError("Por favor adjunte un archivo de formato xlsx");
      setFileSelected(false);
      return;
    }

    setFile(selectedFile);
    setFileSelected(true);
  };

  const fileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowError(false);
    const selectedFile = e.target.files?.[0];
    if (!selectedFile) {
      setFileSelected(false);
      return;
    }

    const type = selectedFile.name.split('.').pop();
    if (type !== "xlsx") {
      setShowError(true);
      setFileDropError("Por favor adjunte un archivo de formato xlsx");
      setFileSelected(false);
      return;
    }

    const newFileSelected = (fileSelected || true);
    setFile(selectedFile);
    setFileSelected(newFileSelected);

    setUploading(true);
    const uploadedQuestions = uploadQuestions(selectedFile, false)
      .then((uploadedQuestions) => {
        if (uploadedQuestions[1].length > 0) {
          setRepeatedModal(true);
          setRepeated(uploadedQuestions[1].length);
        }
        if (uploadedQuestions[0].length > 0) {
          showSuccessToast(`El archivo ${selectedFile.name} ha insertado con éxito ${uploadedQuestions[0].length} preguntas`);
        }
      })
      .catch((error) => {
        setShowError(true);
        const castedError = errorService.castError(error);
        setFileDropError(JSON.stringify(castedError.message));
        showErrorToast(`Error en subir el archivo ${file?.name}`);
        console.error(`Error en uploadFile: \n${error}`);

        const errorCode = error.response?.data?.errorCode || HTTP_CODES.BAD_REQUEST;
        switch (errorCode) {
          case HTTP_CODES.UNSUPPORTED_MEDIA_TYPE:
            showErrorToast('Por favor adjunte un archivo xlsx');
            break;
          case HTTP_CODES.UNPROCESSABLE_ENTITY:
            showErrorToast('Por favor adjunte un archivo xlsx con preguntas');
            break;
          default:
            showErrorToast('Error al subir el archivo');
            break;
        }
      })
      .finally(() => {
        setUploading(false);
      });
  };

  const uploadFile = async () => {
    if (!fileSelected) {
      setShowError(true);
      setFileDropError("Por favor seleccione un archivo");
      return;
    }

    try {
      setUploading(true);
      const uploadedQuestions = await uploadQuestions(file, false);

      if (uploadedQuestions[1].length > 0) {
        setRepeatedModal(true);
        setRepeated(uploadedQuestions[1].length);
      }
      if (uploadedQuestions[0].length > 0) {
        showSuccessToast(`El archivo ${file?.name} ha insertado con éxito ${uploadedQuestions[0].length} preguntas`);
      }
    }
    catch (error: any) {
      setShowError(true);
      const castedError = errorService.castError(error);
      setFileDropError(JSON.stringify(castedError.message));
      showErrorToast(`Error en subir el archivo ${file?.name}`);
      console.error(`Error en uploadFile: \n${error}`);

      const errorCode = error.response?.data?.errorCode || HTTP_CODES.BAD_REQUEST;
      switch (errorCode) {
        case HTTP_CODES.UNSUPPORTED_MEDIA_TYPE:
          showErrorToast('Por favor adjunte un archivo xlsx');
          break;
        case HTTP_CODES.UNPROCESSABLE_ENTITY:
          showErrorToast('Por favor adjunte un archivo xlsx con preguntas');
          break;
        default:
          showErrorToast('Error al subir el archivo');
          break;
      }
    }
    finally {
      setUploading(false);
    }
  };

  const uploadRepeatedFile = async () => {
    setRepeatedModal(false);
    if (!fileSelected) {
      console.error('uploadRepeatedFile: No file selected');
      return;
    }

    try {
      await uploadQuestions(file, true);
      showSuccessToast(`El archivo ${file?.name} ha subido con éxito ${repeated} preguntas repetidas`);
    }
    catch (error: any) {
      setShowError(true);
      const castedError = errorService.castError(error);
      setFileDropError(JSON.stringify(castedError.message));
      showErrorToast(`Error en insertar preguntas duplicadas del archivo ${file?.name}`);
    }
  };

  return (
    <>
      <ConfirmationModal
        text={`Hay ${repeated} preguntas repetidas, ¿quieres reemplazarlas?`}
        onAccept={uploadRepeatedFile}
        onClose={() => setRepeatedModal(false)}
        visible={viewRepeatedModal}
      />

      <form id="form-file-upload" className="mt-10 flex flex-col gap-5">
        {fileDropError && showError && <span className="file-drop-error">{fileDropError}</span>}

        <div
          className="transition border-[3px] py-[15vh] text-primary-100 cursor-pointer border-dashed border-primary-100 w-[50vw] text-lg text-center hover:border-primary-300"
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
          style={{ border: dragOver ? "3px dashed yellowgreen" : "", borderStyle: 'dashed' }}
        >
          <label className="cursor-pointer" htmlFor="file">
            {fileSelected
              ? (<h1 className="text-[100%] text-center">{file?.name ?? ''}</h1>)
              : (<h1 style={{ color: dragOver ? "yellowgreen" : "" }} className="cursor-pointer pointer-events-none">
                  {dragOver ? "Arrastra aquí..." : "Selecciona o arrastra el archivo aquí..."}
                </h1>)
            }
          </label>
          <input type="file" name="file" id="file" onChange={fileSelect} hidden />
        </div>

        <UploadQuestionsButton onClick={uploadFile} disabled={false}>
          {uploading ? 'Espere...' : 'Subir archivo'}
        </UploadQuestionsButton>
      </form>
    </>
  );
}
